import axios from "axios";

import { POPUP } from "@/state/popup";
export const ResolveAPI = function($bvToast, response, callback = {}) {
  var data;
  if (response.status) {
    data = "Success";
    POPUP.popupClick(data);
    callback.success instanceof Function && callback.success();
    return true;
  } else {
    data = "Error";
    POPUP.popupClickNot(data);
    callback.error instanceof Function && callback.error();
    return false;
  }
};
export const API_BASE = "https://lp.estetikinternational.com/api/";
// export const API_BASE = "http://127.0.0.1:8000/api/";

export const API = {
  Get(url) {
    return axios.get(API_BASE + url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
          "Content-Type, X-Auth-Token, Origin, Authorization",
      },
    });
  },
  async Post(url, data = {}, header = {}) {
    if (data instanceof FormData) {
      data.append("token", localStorage.token);
      header = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      };
    } else {
      data.token = localStorage.token;
    }
    const response = await axios.post(API_BASE + url, data, {
      headers: header,
    });

    return response;
  },

  LandingPage: {
    Index: "landingPage/index",
    UpdateList: "landingPage/update-list/",
    Update: "landingPage/update/",
    CreateIndex: "landingPage/create-index",
    Create: "landingPage/create",
    Status: "landingPage/status/",
    Delete: "landingPage/delete/",
    Clone: "landingPage/copy/",
    CRMList: "landingPage/crm-list/",
    CRM: "landingPage/crm/",
  },
  Languages: {
    Index: "languages",
  },
  Auth: {
    Login: "auth/login",
    Register: "auth/register",
    CheckToken: "auth/check-token",
    userController: "auth/user-controller/",
    CompanyRegister: "auth/company-register",
  },
};
